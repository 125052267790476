import React from 'react'
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms And Condition</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="col-lg-5">
                        <div className="copyright-left text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                <li><a href="https://www.facebook.com/blackparrotapps" target="_blank" rel="noreferrer"><FiFacebook /></a></li>
                                <li><a href="https://www.instagram.com/blackparrotapps" target="_blank" rel="noreferrer"><FiTwitter /></a></li>
                                <li><a href="https://www.instagram.com/blackparrotapps" target="_blank" rel="noreferrer"><FiInstagram /></a></li>
                                <li><a href="https://www.linkedin.com/company/black-parrot-ofc" target="_blank" rel="noreferrer"><FiLinkedin /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-end">
                            <p className="copyright-text">© Todos Direitos Reservados - Black Parrot Desenvolvimento {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;