import React from 'react'
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BrandThree from '../elements/brand/BrandThree';
import CounterUpFour from "../elements/counterup/CounterUpFour";

const HomeDefault = () => {

    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
                {/* <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" /> */}

                {/* Start Slider Area  */}
                <div className="slider-area  variation-default height-750 bg_image" data-black-overlay="2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-01.png)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 mb--20">
                                <img src={`${process.env.PUBLIC_URL}/images/logo/Logo_Horizontal.png`} alt="Logo Black Parrot"/>
                            </div>
                            <div className="col-lg-12">
                                <div className="inner text-left">
                                    <h1 className="title display-one">CONQUISTE MAIS<br/>EM MENOS TEMPO </h1>
                                    <p className="description b1">Transforme desafios em oportunidades e faça sua empresa<br/><b>bater asas</b> com nossa solução de <b>software sob demanda!</b></p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium btn-icon" target="_blank" rel="noreferrer" href="https://www.instagram.com/blackparrotapps">VAMOS FAZER UM PROJETO<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start About Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Conheça a Black Parrot, sua parceira especializada em desenvolvimento de soluções tecnológicas. 🦜🚀</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Da criação de websites e sistemas até plataformas e aplicativos, oferecemos um leque completo de ferramentas para impulsionar o seu sucesso.<br /><br />O nosso compromisso vai além do desenvolvimento técnico; concentramos nossos esforços em inovação, criatividade e na aprimorada experiência de usuário.<br /><br />Com paixão pela inovação, comprometimento com a excelência e um olhar atento às necessidades educacionais, estamos determinados a criar um impacto positivo no cenário tecnológico, tornando-nos líderes reconhecidos no desenvolvimento de soluções inovadoras para o setor educacional.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <Separator />
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapBottom pt--100">
                    <div className="container">
                        <ServiceOne
                            serviceStyle="service__style--1 icon-circle-style"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Processo de Desenvolvimento"
                                    title="Como Funciona o Desenvolvimento?"
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                {/* Start Portfolio Area  */}
                {/* <div className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Nossos Projetos"
                                    title="Alguns de nossos +60 projetos!"
                                    description=""
                                />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
                    </div>
                </div> */}
                {/* End Portfolio Area  */}

                {/* Start Video Area  */}
                {/* <div className="rwt-video-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            {PopupData.map((item) => (
                                <div className="col-lg-12" key={item.id}>
                                    <VideoItem galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Video Area  */}


                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                {/* <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Feedback de Clientes"
                                    title="Nossos Clientes Satisfeitos."
                                    description=""
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div> */}
                {/* End Elements Area  */}

                {/* <Separator />
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Latests News"
                                    title="Our Latest News."
                                    description="We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Copyright />

            </main>

        </>
    )
}
export default HomeDefault

