import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

const TimelineData = [
    {
        id: "1",
        date: "1º Passo",
        title: "Descoberta",
        description: "Mapeamos tudo o que o software precisa ter, direcionando o cliente para resolver da melhor forma os seus problemas. Caso necessário fazemos uma pesquisa de público e mercado para ajudar a direcionar o software.",
        image: "dev-02",
        workingStep: [
            // {
            //     stepTitle: "Gain your works",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "2",
        date: "2º Passo",
        title: "Design",
        description: "Na fase de design, a equipe começa a desenvolver ideias para soluções que atendam aos requisitos do usuário e às metas do projeto. Isso pode incluir a criação de protótipos, a realização de testes de usabilidade e a iteração de ideias com a equipe e com os usuários.",
        image: "dev-03",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "3º Passo",
        title: "Desenvolvimento",
        description: "A equipe começa a construir a solução, escolhendo as melhores tecnologias que se adaptam ao projeto. Implementando funcionalidades e telas em código.",
        image: "dev-04",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) => (
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index) => (
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation
                                                            animateIn="fadeInUp"
                                                            animateOut="fadeInOut"
                                                            animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation
                                                            animateIn="fadeInUp"
                                                            animateOut="fadeInOut"
                                                            animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            <ScrollAnimation
                                                animateIn="fadeInUp"
                                                animateOut="fadeInOut"
                                                animateOnce={true}>
                                                <a className="btn-default btn-large" href="/contact"><span>Desenvolver Agora</span> <i className="icon"><FiArrowRight /></i></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
